import { render, staticRenderFns } from "./Template.vue?vue&type=template&id=2191242b"
var script = {}
import style0 from "./Template.vue?vue&type=style&index=0&id=2191242b&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ec2-user/.jenkins/workspace/MATKP - Dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2191242b')) {
      api.createRecord('2191242b', component.options)
    } else {
      api.reload('2191242b', component.options)
    }
    module.hot.accept("./Template.vue?vue&type=template&id=2191242b", function () {
      api.rerender('2191242b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/404/Template.vue"
export default component.exports